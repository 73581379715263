import React from "react"
import CommonStore from "../stores/CommonStore";
import VideoJsPlayerStore from "../stores/VideoJsPlayerStore";

/**
 * React hooks를 사용하는 컴포넌트에서 store를 가져올 때 사용한다.
 * 참조) https://mobx-react.js.org/recipes-migration#hooks-for-the-rescue
 */
export const storesContext = React.createContext({
    commonStore: CommonStore,
    videoJsPlayerStore: VideoJsPlayerStore
})

export const useStores = () => {
    return React.useContext(storesContext)
}