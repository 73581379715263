import * as MaterialUI from "@material-ui/core";
import * as MaterialUIIcon from "@material-ui/icons";
import React, {useEffect} from "react";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {useStores} from "../hooks/useStores";
import { useTranslation } from "react-i18next";
import i18next from "../i18n/i18n";

const useStyles = makeStyles(theme => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    gridListRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflowX: 'hidden',
        overflowY: 'hidden',
        overflowScrolling: "touch",
        WebkitOverflowScrolling: "touch",
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        [theme.breakpoints.up('xl')]: { //1920
            width: 740,
        },
        [theme.breakpoints.down('lg')]: { //960
            width: 740,
        },
        [theme.breakpoints.down('md')]: { //960
            width: 740,
        },
        [theme.breakpoints.down('sm')]: { //960
            width: 550,
        },
        [theme.breakpoints.down('xs')]: { //600
            width: 310,
        },
    },
    itemContentsBar: {
        zIndex:99,
        padding: 10,
        bottom: 0,
        left: 0,
        right: 0,
        height: 48,
        display: 'flex',
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        alignItems: 'center',
        fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    },
    itemTitle: {
        zIndex:98,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
}));

export const BabySharkStore = () => {
    const classes = useStyles();
    const theme = useTheme();
    const {t} = useTranslation();
    const {commonStore} = useStores();
    const screenExtraLarge = useMediaQuery(theme.breakpoints.only('xl'));
    const screenLarge = useMediaQuery(theme.breakpoints.only('lg'));
    const screenMedium = useMediaQuery(theme.breakpoints.only('md'));
    const screenSmall = useMediaQuery(theme.breakpoints.only('sm'));
    const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
    const screenNarrow = useMediaQuery('(max-width:340px)');

    const getScreenWidth = () => {
        if (screenExtraLarge) {
            return 1;
        } else if (screenNarrow) {
            return 1;
        } else if (screenLarge) {
            return 1;
        } else if (screenMedium) {
            return 1;
        } else if (screenSmall) {
            return 1;
        } else if (screenExtraSmall) {
            return 1;
        } else {
            return 1;
        }
    }

    const getCellHeight = () => {
        if (screenExtraLarge) {
            return 500;
        } else if (screenNarrow) {
            return 500;
        } else if (screenLarge) {
            return 500;
        } else if (screenMedium) {
            return 200;
        } else if (screenSmall) {
            return 200;
        } else if (screenExtraSmall) {
            return 200;
        } else {
            return 300;
        }
    }
    const gridListData = [
        {"link":"https://store.pinkfong.com/628/","id":1,"titleEn":"Pinkfong Sound Workbook Math Set","subTitleEn":"Pinkfong Sound Workbook Math Set","title":"핑크퐁 사운드워크북 수학세트","subTitle":"핑크퐁 사운드워크북 수학세트","price": 20000,"discount":25,"img":"https://store-cf.pinkfong.com/__sized__/products/PTNBO008/cf/06/1a/cdb2494a976973d20e5e91ac-crop-c0-5__0-5-600x600-85.jpg"},
        {"link":"https://store.pinkfong.com/621/","id":2,"titleEn":"Pinkfong Bag Sticker Series (11 types)","subTitleEn":"Pinkfong Bag Sticker Series (11 types)","title":"핑크퐁 가방스티커 시리즈 (총 11종)","subTitle":"핑크퐁 가방스티커 시리즈 (총 11종)","price": 6800,"discount":39,"img":"https://store-cf.pinkfong.com/__sized__/products/PTSSA001/c2/ca/fa/69b54212882e860e1366b76d-crop-c0-5__0-5-600x600-85.jpg"},
        {"link":"https://store.pinkfong.com/613/","id":3,"titleEn":"Pinkfong Sound Workbook Korean Set","subTitleEn":"Pinkfong Sound Workbook Korean Set","title":"핑크퐁 사운드워크북 한글세트","subTitle":"핑크퐁 사운드워크북 한글세트","price": 20000,"discount":25,"img":"https://store-cf.pinkfong.com/__sized__/products/PT1014/61/81/07/f8e2442984084a3a29f3bacf-crop-c0-5__0-5-600x600-85.jpg"},
        {"link":"https://store.pinkfong.com/411/","id":4,"titleEn":"Pinkfong Shark Family Bath Squirt","subTitleEn":"Pinkfong Shark Family Bath Squirt","title":"핑크퐁 상어가족 목욕물총","subTitle":"핑크퐁 상어가족 목욕물총","price": 23000,"discount":21,"img":"https://store-cf.pinkfong.com/__sized__/products/PT354/68/86/bc/379b4c9d95400ae2677b233f-crop-c0-5__0-5-600x600-85.jpg"},
        {"link":"https://store.pinkfong.com/494/","id":5,"titleEn":"Exciting Baby Shark Tooth Game","subTitleEn":"Exciting Baby Shark Tooth Game","title":"두근두근 아기상어 이빨게임","subTitle":"두근두근 아기상어 이빨게임","price": 30000,"discount":33,"img":"https://store-cf.pinkfong.com/__sized__/products/IT657/89/ab/01/575744929627a0c6ad1bb0c9-crop-c0-5__0-5-600x600-85.jpg"},
        {"link":"https://store.pinkfong.com/623/","id":6,"titleEn":"Pinkfong Kamika 10 kinds","subTitleEn":"Pinkfong Kamika 10 kinds","title":"핑크퐁 카미카 10종","subTitle":"핑크퐁 카미카 10종","price": 50000,"discount":44,"img":"https://store-cf.pinkfong.com/__sized__/products/DTSOT003/02/4b/aa/b5904866b23840b6f26f403c-crop-c0-5__0-5-600x600-85.jpg"},
    ]

    const handleSelectedProduct = (event) => {
        window.location.href=event.currentTarget.dataset.link;
    }

    useEffect(()=>{
        commonStore.setDetailMode(false);
    },[])

    return (
        <MaterialUI.Box m={3}>
            <div className={classes.gridListRoot}>
                <MaterialUI.GridList cols={getScreenWidth()} cellHeight={getCellHeight()} className={classes.gridList}>
                    { gridListData.map((tile) => (
                        <MaterialUI.GridListTile key={tile.id} data-id={tile.id} data-link={tile.link} onClick={handleSelectedProduct} style={{marginBottom:5}}>
                            <MaterialUI.GridListTileBar
                                className={classes.itemContentsBar}
                                title={commonStore.locale === 'en' ? tile.titleEn:tile.title}
                                subtitle={commonStore.locale === 'en' ? tile.subTitleEn:tile.subTitle}
                                actionIcon={ <div style={{display:'flex', height:65, margin:0, padding:0}}>
                                    <div style={{margin:5, color:'#ebc334', width: 50, height: 50, fontWeight:'bold', fontSize:20, border:'3px solid #ebc334', borderRadius:'50%', textAlign:'center', lineHeight:2.5}}>
                                        {tile.discount}%
                                    </div>
                                    <div style={{height: 50, textAlign:'center', marginTop:14}}>
                                        <p style={{textDecoration:'line-through', color:'#bfbfbf', fontSize:13, margin:0, padding:0}}>{tile.price.toLocaleString()}{t('won')}</p>
                                        <p style={{color:'#ff5cc0', fontWeight:'bold', margin:0, padding:0}}>{(tile.price - (tile.price * tile.discount / 100)).toLocaleString()}{t('won')}</p>
                                    </div>
                                </div>}
                            />
                            <img src={tile.img} alt={tile.title} />
                        </MaterialUI.GridListTile>
                    ))}
                </MaterialUI.GridList>
            </div>
        </MaterialUI.Box>
    )
}