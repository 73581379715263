import React from 'react';
import superagentPromise from 'superagent-promise';
import _superagent from 'superagent';
import jwt from 'jwt-decode';
import commonStore from "./stores/CommonStore";

const superagent = superagentPromise(_superagent, global.Promise);

const API_ROOT = process.env.REACT_APP_API_URL+'/api/v1';

const encode = encodeURIComponent;

const handleErrors = err => {
    if (err && err.response && err.response.status === 401) {
        //logout 처리
        commonStore.setUser(undefined);
        commonStore.setToken(undefined)
    }
    return err;
};

const responseBody = res => res.body;


const tokenPlugin = req => {
    if (commonStore.token) {
        req.set({'Authorization':`Token ${commonStore.token}`, 'Content-Type':'application/json'});
    }
};

const requests = {
    del: url =>
        superagent
            .del(`${API_ROOT}${url}`)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseBody),
    get: url =>
        superagent
            .get(`${API_ROOT}${url}`)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseBody),
    put: (url, body) =>
        superagent
            .put(`${API_ROOT}${url}`, body)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseBody),
    post: (url, body) =>
        superagent
            .post(`${API_ROOT}${url}`, body)
            .use(tokenPlugin)
            .end(handleErrors)
            .then(responseBody),
};

export const Auth = {
    current: () =>
        requests.get('/user/'),
    login: (email, password) =>
        requests.post('/users/login', { user: { email, password } }),
    register: (username, email, password) =>
        requests.post('/users', { user: { username, email, password } }),
    save: user =>
        requests.put('/user', { user })
};

export const BroadcastSchedule = {
    save: (epgs) =>
        requests.post('/broadcast/schedule', {epgs}),
    get: (date) =>
        requests.get(`/broadcast/schedule/${date}`),
    getStartDates: (year, month) =>
        requests.get(`/broadcast/schedule/${year}/${month}`),
    del: (date) =>
        requests.del(`/broadcast/schedule/${date}`),
}