import {action, observable} from 'mobx';

class VideoJsPlayerStore {
    @observable
    loaded = false;

    @observable
    topDrawerOpen = false;

    @observable
    rightDrawerOpen = false;

    @observable
    bottomDrawerOpen = false;

    @observable
    currentTimeline = 0;

    @observable
    broadcastScheduleList = [];

    constructor() {
    }

    @action setLoaded(loaded) {
        this.loaded = loaded
    }

    @action setTopDrawerOpen(topDrawerOpen) {
        this.topDrawerOpen = topDrawerOpen;
    }

    @action setRightDrawerOpen(rightDrawerOpen) {
        this.rightDrawerOpen = rightDrawerOpen;
    }

    @action setBottomDrawerOpen(bottomDrawerOpen) {
        this.bottomDrawerOpen = bottomDrawerOpen;
    }

    @action setCurrentTimeline(currentTimeline) {
        this.currentTimeline = currentTimeline;
    }

    @action setBroadcastScheduleList(broadcastScheduleList) {
        this.broadcastScheduleList = broadcastScheduleList;
    }
}
export default new VideoJsPlayerStore();