import React from 'react';
import videojs from 'video.js';
import 'videojs-overlay';
import 'videojs-youtube';
import 'videojs-http-source-selector'
import 'videojs-contrib-quality-levels'

const styles = {
    wrapper:{
        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.5)',
        margin: '0 auto',
    }
};
export class YouTube4VideoJsPlayer extends React.Component {
    componentDidMount() {
        window.videojs = videojs;
        this.player = window.videojs(this.videoNode, this.props, function onPlayerReady() {
            console.log('onPlayerReady', this)
        });

        this.player.httpSourceSelector();
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    }

    render() {
        return (
            <div id={"vjs-wrapper"} style={styles.wrapper}>
                <div data-vjs-player>
                    <video id={"video-js"} playsInline crossOrigin={"anonymous"} ref={ node => this.videoNode = node } className={"video-js"}
                           data-setup='{"techOrder": ["youtube"]}'></video>
                </div>
            </div>
        )
    }
}