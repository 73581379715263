import React from 'react';
import {VideoJsPlayer} from '../components/VideoJsPlayer';
import * as MaterialUI from "@material-ui/core";
import * as MaterialUIIcon from "@material-ui/icons";
import {AccountButton, BroadcastScheduleButton, VideoJsOverlay} from "../components/VideoJsOverlay";

const styles = {
    container: {
        zIndex: 11,
        overflowX: 'hidden',
        overflowY: 'hidden',
        overflowScrolling: "touch",
        WebkitOverflowScrolling: "touch",
    }
};

export const BroadcastVideoPlayer = () => {
    const videoJsOptions = {
        autoplay: 'any',
        controls: true,
        preload: 'auto',
        muted: true,
        playsInline: true,
        fluid: false,
        fullscreen: true,
        responsive: true,
        textTrackSettings: false,
        aspectRatio: '16:9',
        controlBar: {
            volumePanel: {
                inline: false,
                volumeControl: {
                    vertical: true
                }
            }
        },
        sources: [{
            type: "application/x-mpegURL",
            src: process.env.REACT_APP_BROADCAST_STREAM_URL
        }],
    }

    return (
        <MaterialUI.Container maxWidth={"xl"} style={styles.dayContainer}>
            <MaterialUI.Box m={8}>
                <VideoJsPlayer showCustomButton={true} forcePlay={true} {...videoJsOptions}/>
            </MaterialUI.Box>
        </MaterialUI.Container>
    );
}
