import React, {useEffect, useState} from 'react';
import * as MaterialUI from "@material-ui/core";
import * as MaterialUIIcon from "@material-ui/icons";
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import i18next from "../i18n/i18n";
import Carousel from 'react-material-ui-carousel'
import {useStores} from "../hooks/useStores";
import FileSaver from 'file-saver';
import {observer, useObserver} from "mobx-react";

const useStyles = makeStyles(theme => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    gridListRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflowX: 'hidden',
        overflowY: 'hidden',
        overflowScrolling: "touch",
        WebkitOverflowScrolling: "touch",
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        [theme.breakpoints.up('xl')]: { //1920
            width: 1344,
            height: 510,
        },
        [theme.breakpoints.down('lg')]: { //960
            width: 1200,
            height: 510,
        },
        [theme.breakpoints.down('md')]: { //960
            width: 740,
            height: 270,
        },
        [theme.breakpoints.down('sm')]: { //960
            width: 550,
            height: 410,
        },
        [theme.breakpoints.down('xs')]: { //600
            width: 360,
            height: 590,
        },
    },
    selectedIcon: {
        width: 80,
    },
    selectedBg: {
        zIndex:99,
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
    },
    tileBgUnlock: {
        zIndex:98,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    tileBgLock: {
        zIndex:98,
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(255,255,255,0.5)',
        width: '100%',
        height: '100%'
    },
    itemImage: {
        [theme.breakpoints.up('sm')]: { //960
            height: 480,
        },
        [theme.breakpoints.down('sm')]: { //960
            width: 300,
            height: 480
        },
        [theme.breakpoints.down('xs')]: { //600
            width: 330,
            height: 480
        },
    },
    itemContentsBar: {
        zIndex:99,
        padding: 10,
        bottom: 4,
        left: 0,
        right: 0,
        height: 48,
        display: 'flex',
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        alignItems: 'center',
        fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    },
    itemContentTitle:{
        color: '#fff',
        overflow: 'hidden',
        flexGrow: 1,
        marginLeft: '16',
        marginRight: '16'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    sectionMobile: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

const style = {
    dayContainer: {
        zIndex: 11,
        overflowX: 'hidden',
        overflowY: 'hidden',
        overflowScrolling: "touch",
        WebkitOverflowScrolling: "touch",
        backgroundImage: `url(./img/dayWallpaper.jpg)`,
        backgroundSize: 'contain',
        padding: 0
    },
    nightContainer: {
        zIndex: 11,
        overflowX: 'hidden',
        overflowY: 'hidden',
        overflowScrolling: "touch",
        WebkitOverflowScrolling: "touch",
        backgroundImage: `url(./img/nightWallpaper.jpg)`,
        backgroundSize: 'contain',
        padding: 0
    },
}

export const BabySharkActivity = observer(() => {
    const classes = useStyles();
    const theme = useTheme();
    const {commonStore} = useStores();
    const [checked, setChecked] = React.useState(false);
    const [openMessageDialog, setOpenMessageDialog] = React.useState(false);
    const [openShareDialog, setOpenShareDialog] = React.useState(false);
    const [shareLink, setShareLink] = React.useState(null);
    const [locale, setLocale] = React.useState('en');
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = React.useState(true);
    const [selectedItem, setSelectedItem] = React.useState(1);
    const [carouselData, setCarouselData] = React.useState(null);
    const [copySuccess, setCopySuccess] = React.useState(false);

    const screenExtraLarge = useMediaQuery(theme.breakpoints.only('xl'));
    const screenLarge = useMediaQuery(theme.breakpoints.only('lg'));
    const screenMedium = useMediaQuery(theme.breakpoints.only('md'));
    const screenSmall = useMediaQuery(theme.breakpoints.only('sm'));
    const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
    const screenNarrow = useMediaQuery('(max-width:340px)');

    const getScreenWidth = () => {
        if (screenExtraLarge) {
            return 3;
        } else if (screenNarrow) {
            return 3;
        } else if (screenLarge) {
            return 3;
        } else if (screenMedium) {
            return 3;
        } else if (screenSmall) {
            return 2;
        } else if (screenExtraSmall) {
            return 2;
        } else {
            return 3;
        }
    }

    const getCellHeight = () => {
        if (screenExtraLarge) {
            return 250;
        } else if (screenNarrow) {
            return 250;
        } else if (screenLarge) {
            return 220;
        } else if (screenMedium) {
            return 130;
        } else if (screenSmall) {
            return 130;
        } else if (screenExtraSmall) {
            return 190;
        } else {
            return 250;
        }
    }

    const handleClickOpenMessageDialog = () => {
        setOpenMessageDialog(true);
    };

    const handleCloseMessageDialog = () => {
        setOpenMessageDialog(false);
    };

    const handleClickOpenShareDialog = () => {
        setOpenShareDialog(true);
    };

    const handleCloseShareDialog = () => {
        setOpenShareDialog(false);
    };

    const gridListData = [
        {"link":"/1/all.zip","lock":false,"id":1,"titleEn":"Decorating the house","subTitleEn":"Decorating the house","title":"집꾸미기","subTitle":"일단 집을 사자","list":["/1/1.png", "/1/2.png", "/1/3.png", "/1/4.png"]},
        {"link":"/2/all.zip","lock":false,"id":2,"titleEn":"Coloring","subTitleEn":"Coloring","title":"색칠놀이","subTitle":"색칠을 해보자아","list":["/2/1.png", "/2/2.png", "/2/3.png", "/2/4.png"]},
        {"link":"/3/all.zip","lock":false,"id":3,"titleEn":"Maze","subTitleEn":"Maze","title":"미로찾기","subTitle":"사랑의 미로여","list":["/3/1.png", "/3/2.png", "/3/3.png", "/3/4.png"]},
        {"link":"/4/all.zip","lock":false,"id":4,"titleEn":"Draw by number","subTitleEn":"Draw by number","title":"숫자따라그리기","subTitle":"우선 숫자를 먼저 배우자","list":["/4/1.png", "/4/2.png", "/4/3.png", "/4/4.png"]},
        {"link":"/5/all.zip","lock":true,"id":5,"titleEn":"Dress up","subTitleEn":"Dress up","title":"옷입히기","subTitle":"1년내내 같은옷","list":["/5/1.png", "/5/2.png", "/5/3.png", "/5/4.png"]},
        {"link":"/6/all.zip","lock":true,"id":6,"titleEn":"Schedule collection","subTitleEn":"Schedule collection","title":"계획표 모음","subTitle":"너는 다 계획이 있구나","list":["/6/1.png", "/6/2.png", "/6/3.png"]},
    ]

    useEffect(()=> {
        const images = [];
        gridListData[0].list.map(url=> {images.push(process.env.REACT_APP_CDN_DOMAIN+'/activity'+url)});
        cacheImages(images).then(r => setIsLoading(false));
        setSelectedItem(1);
        setCarouselData(gridListData[0]);
        commonStore.setDetailMode(false);
    },[])

    const handleSelectedActivity = (event) => {
        if (event.currentTarget.dataset.lock === 'true' && commonStore.logged === false) {
            setOpenMessageDialog(true);
            return null;
        }

        const id = Number(event.currentTarget.dataset.id);
        const idx = id-1;

        const images = [];
        gridListData[idx].list.map(url=> {images.push(process.env.REACT_APP_CDN_DOMAIN+'/activity'+url)});
        cacheImages(images).then(r => setIsLoading(false));

        setSelectedItem(id);
        setCarouselData(gridListData[idx]);
        setChecked(false);
        setCopySuccess(false);

        if (commonStore.deviceSize === 'sm' || commonStore.deviceSize === 'xs') {
            window.localStorage.setItem('activityDetailSelectedNumber', idx);
            window.location.href='/#/detail';
        }
    }

    const Item = (props) => {
        const imgUrl = `${process.env.REACT_APP_CDN_DOMAIN}/activity${props.item}`
        return (
            <MaterialUI.Box>
                <img src={imgUrl} className={classes.itemImage} />
                <div className={classes.itemContentsBar}>
                    <div className={classes.itemContentTitle} style={{width:'100%'}}>
                        <MaterialUI.Typography variant={"subtitle1"}>{commonStore.locale === 'en'? props.data.titleEn:props.data.title}</MaterialUI.Typography>
                        <MaterialUI.Typography variant={"body2"}>{commonStore.locale === 'en'? props.data.subTitleEn:props.data.subTitle}</MaterialUI.Typography>
                    </div>
                    <MaterialUI.IconButton onClick={()=> FileSaver.saveAs(imgUrl, 'pinkfong.png')}>
                        <MaterialUIIcon.CloudDownload fontSize={"small"} style={{color:'white', border:'1px solid', padding: 10, borderRadius:'50%'}}/>
                    </MaterialUI.IconButton>
                </div>
            </MaterialUI.Box>
        )
    }

    const cacheImages = async(srcArray) => {
        setIsLoading(true);
        const promises = await srcArray.map((src) => {
            return new Promise(((resolve, reject) => {
                const img = new Image();
                img.src = src;
                img.onload = resolve;
                img.onerror = reject;
            }))
        })
        await Promise.all(promises);
    }

    let shareLinkRef = null;
    const handleCopyToClipboard = (event) => {
        shareLinkRef.select();
        document.execCommand('copy');
        setCopySuccess(true);
    };

    return useObserver( () =>
        <>
        <MaterialUI.Container maxWidth={"xl"} style={style.dayContainer} className={classes.sectionDesktop}>
            <MaterialUI.Box display="flex" justifyContent="center" mt={8} mb={8} p={0}>
                <MaterialUI.Box p={0}>
                    {isLoading ? <div style={{width:640, height:484, display:'flex', alignItems: 'center', justifyContent: 'center'}}><MaterialUI.CircularProgress/></div>:
                        <Carousel
                            autoPlay={false}
                            indicators={false}
                            animation={"slide"}
                            navButtonsAlwaysInvisible={false}
                            navButtonsAlwaysVisible={true}
                        >
                            {carouselData.list.map((item, i) => <Item key={i} item={item} data={carouselData} /> )}
                        </Carousel>
                    }
                </MaterialUI.Box>
            </MaterialUI.Box>
        </MaterialUI.Container>
        <MaterialUI.Box m={3}>
            <div className={classes.gridListRoot}>
                <MaterialUI.GridList cols={getScreenWidth()} cellHeight={getCellHeight()} className={classes.gridList}>
                    { gridListData.map((tile) => (
                            <MaterialUI.GridListTile key={tile.id} data-id={tile.id} data-download={tile.link} data-lock={tile.lock} onClick={handleSelectedActivity}>
                                {tile.lock && commonStore.logged === false ?
                                    <div className={classes.tileBgLock}>
                                        <MaterialUI.GridListTileBar
                                            title={t('requiredSignIn')}
                                            subtitle={<span>{t('requiredSignInMessage')}</span>}
                                            actionIcon={
                                                <MaterialUI.IconButton>
                                                    <MaterialUIIcon.Lock style={{color:'white'}}/>
                                                </MaterialUI.IconButton>
                                            }
                                        />
                                    </div> :
                                    <>
                                        <div className={classes.sectionDesktop}>
                                            <div className={classes.tileBgUnlock} style={{display: selectedItem === tile.id? "none":"block"}}>
                                                <MaterialUI.GridListTileBar style={{padding:0}}
                                                    title={commonStore.locale === 'en' ? tile.titleEn:tile.title}
                                                    subtitle={<span>{commonStore.locale === 'en' ? tile.subTitleEn : tile.subTitle}</span>}
                                                />
                                            </div>
                                        </div>
                                        <div className={classes.sectionMobile}>
                                            <div className={classes.tileBgUnlock}>
                                                <MaterialUI.GridListTileBar style={{padding:0}}
                                                                            title={commonStore.locale === 'en' ? tile.titleEn:tile.title}
                                                                            subtitle={<span>{commonStore.locale === 'en' ? tile.subTitleEn : tile.subTitle}</span>}
                                                                            actionIcon={
                                                                                <MaterialUI.Box>
                                                                                    <MaterialUI.Box style={{width:80}}>
                                                                                        <MaterialUI.IconButton style={{padding:5}} onClick={()=> {setShareLink(process.env.REACT_APP_CDN_DOMAIN+'/activity'+tile.link); setOpenShareDialog(true);}}>
                                                                                            <MaterialUIIcon.Share fontSize={"small"} style={{color:'white', border:'1px solid', padding: 2, borderRadius:'50%'}}/>
                                                                                        </MaterialUI.IconButton>
                                                                                        <MaterialUI.IconButton style={{padding:5}} onClick={()=> FileSaver.saveAs(
                                                                                            process.env.REACT_APP_CDN_DOMAIN+'/activity'+tile.link, 'all.zip')}>
                                                                                            <MaterialUIIcon.CloudDownload fontSize={"small"} style={{color:'white', border:'1px solid',  padding: 2, borderRadius:'50%'}}/>
                                                                                        </MaterialUI.IconButton>
                                                                                    </MaterialUI.Box>
                                                                                </MaterialUI.Box>
                                                                            }
                                                />
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className={classes.sectionDesktop}>
                                    <div className={classes.selectedBg} style={{display: selectedItem === tile.id? "flex":"none"}}>
                                        <MaterialUI.Box>
                                            <MaterialUI.Box>
                                                <MaterialUI.IconButton onClick={()=> {setShareLink(process.env.REACT_APP_CDN_DOMAIN+'/activity'+tile.link); setOpenShareDialog(true);}}>
                                                    <MaterialUIIcon.Share fontSize={"large"} style={{color:'white', border:'1px solid', padding: 10, borderRadius:'50%'}}/>
                                                </MaterialUI.IconButton>
                                                <MaterialUI.IconButton onClick={()=> FileSaver.saveAs(
                                                    process.env.REACT_APP_CDN_DOMAIN+'/activity'+tile.link, 'all.zip')}>
                                                    <MaterialUIIcon.CloudDownload fontSize={"large"} style={{color:'white', border:'1px solid', padding: 10, borderRadius:'50%'}}/>
                                                </MaterialUI.IconButton>
                                            </MaterialUI.Box>
                                        </MaterialUI.Box>
                                    </div>
                                </div>
                                <img src={process.env.REACT_APP_CDN_DOMAIN+"/activity"+tile.list[0]} alt={tile.title} />
                            </MaterialUI.GridListTile>
                    ))}
                </MaterialUI.GridList>
            </div>
        </MaterialUI.Box>
        <MaterialUI.Dialog
            open={openMessageDialog}
            onClose={handleCloseMessageDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <MaterialUI.DialogTitle id="alert-dialog-title">{t('requiredSignInModal')}</MaterialUI.DialogTitle>
            <MaterialUI.DialogContent>
                <MaterialUI.DialogContentText id="alert-dialog-description">
                    {t('requiredSignInMessageModal')}
                </MaterialUI.DialogContentText>
            </MaterialUI.DialogContent>
            <MaterialUI.DialogActions>
                <MaterialUI.Button onClick={handleCloseMessageDialog} color="primary">
                    {t('disagreeButton')}
                </MaterialUI.Button>
                <MaterialUI.Button onClick={handleCloseMessageDialog} color="primary" autoFocus>
                    {t('agreeButton')}
                </MaterialUI.Button>
            </MaterialUI.DialogActions>
        </MaterialUI.Dialog>
            <MaterialUI.Dialog
                open={openShareDialog}
                onClose={handleCloseShareDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <MaterialUI.DialogTitle id="alert-dialog-title">{t('shareModalTitle')}</MaterialUI.DialogTitle>
                <MaterialUI.DialogContent>
                    <input
                        readOnly
                        value={shareLink}
                        ref={(ref) => (shareLinkRef = ref)}
                    />
                </MaterialUI.DialogContent>
                <MaterialUI.DialogActions>
                    <MaterialUI.Button onClick={handleCloseShareDialog} color="primary">
                        {t('closeButton')}
                    </MaterialUI.Button>
                    <MaterialUI.Button onClick={handleCopyToClipboard} color="primary">
                        {copySuccess? t('copiedButton'):t('copyButton')}
                    </MaterialUI.Button>
                </MaterialUI.DialogActions>
            </MaterialUI.Dialog>
        </>
    );
})
