import * as MaterialUI from "@material-ui/core";
import * as MaterialUIIcon from "@material-ui/icons";
import React, {useEffect} from "react";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import i18next from "../i18n/i18n";
import {useStores} from "../hooks/useStores";
import Cookies from "js-cookie";
import { observer, useObserver } from "mobx-react";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
    logo: {
        maxWidth: 100
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    menuIcon : {
        color: 'navy',
        border: '2px solid navy',
        width: 50,
        height: 50,
        marginLeft: 5,
        marginRight: 5
    },
    sectionMobile: {
        display: 'flex',
        color: 'navy',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

const SignInButton = () => {
    const { commonStore } = useStores();
    const {t} = useTranslation();
    const handleSignIn = () => {
        Cookies.set('redirect_uri', window.location.href, {path:'/', domain: process.env.REACT_APP_ROOT_DOMAIN});
        window.location.href=`${process.env.REACT_APP_API_URL}/oauth2/authorization/pinkfong`;
    }

    if (!commonStore.logged) {
        return (
            <MaterialUI.Button style={{width: 100}} variant={"contained"} color="primary" onClick={handleSignIn}>
                {t('signIn')}
            </MaterialUI.Button>
        )
    }
    return null;
}

const SignOutButton = () => {
    const { commonStore } = useStores();
    const {t} = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        window.localStorage.removeItem('jwt');
        commonStore.setLogged(false);
        handleClose();
    };

    if (commonStore.logged) {
        return (
            <MaterialUI.Box>
                <MaterialUI.Button style={{cursor: 'pointer'}} onClick={handleClick}>
                    <span>{commonStore.user.email.split('@')[0]}.</span>
                </MaterialUI.Button>
                <MaterialUI.Menu
                    id="logged-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MaterialUI.MenuItem onClick={handleClose}>My account</MaterialUI.MenuItem>
                    <MaterialUI.MenuItem onClick={handleSignOut}>{t('signOut')}</MaterialUI.MenuItem>
                </MaterialUI.Menu>
            </MaterialUI.Box>
        )
    }
    return null;
}

export const Gnb = observer(() => {
    const classes = useStyles();
    const {commonStore} = useStores();
    const [locale, setLocale] = React.useState('en');
    const {t} = useTranslation();
    const theme = useTheme();
    const screenExtraLarge = useMediaQuery(theme.breakpoints.only('xl'));
    const screenLarge = useMediaQuery(theme.breakpoints.only('lg'));
    const screenMedium = useMediaQuery(theme.breakpoints.only('md'));
    const screenSmall = useMediaQuery(theme.breakpoints.only('sm'));
    const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
    const screenNarrow = useMediaQuery('(max-width:340px)');

    useEffect(()=>{
        if (screenExtraLarge) {
            commonStore.setDeviceSize('xl');
        } else if (screenNarrow) {
            commonStore.setDeviceSize('xxs');
        } else if (screenLarge) {
            commonStore.setDeviceSize('lg');
        } else if (screenMedium) {
            commonStore.setDeviceSize('md');
        } else if (screenSmall) {
            commonStore.setDeviceSize('sm');
        } else if (screenExtraSmall) {
            commonStore.setDeviceSize('xs');
        }
    })

    const sign = commonStore.logged? <SignOutButton/>:<SignInButton/>;

    const handleChangeLanguage = (event) => {
        i18next.changeLanguage(event.target.value);
        setLocale(event.target.value);
        commonStore.setLocale(event.target.value);
    }

    const goHome = () => {
        window.location.href = '/#/';
    }

    const goActivity = () => {
        window.location.href = '/#/activity';
    }

    const goStore = () => {
        window.location.href = '/#/store';
    }

    return useObserver( () =>
        <MaterialUI.AppBar position="sticky" style={{backgroundColor: 'white', display: commonStore.detailMode ? "none":"block"}}>
        <MaterialUI.Toolbar>
                <MaterialUI.Box display="flex" justify="flex-start">
                    <MaterialUI.Box m={1} onClick={goHome}>
                        <img src={"./img/babysharklogo.png"} className={classes.logo} />
                    </MaterialUI.Box>
                </MaterialUI.Box>
                <MaterialUI.Grid container justify="center" wrap="wrap" className={classes.sectionDesktop}>
                    <MaterialUI.Grid item>
                        <MaterialUI.Button aria-label="activity" className={classes.menuIcon} onClick={goActivity}>
                            <MaterialUIIcon.Create/>
                        </MaterialUI.Button>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item>
                        <MaterialUI.Button aria-label="store" className={classes.menuIcon} onClick={goStore}>
                            <MaterialUIIcon.CardGiftcard/>
                        </MaterialUI.Button>
                    </MaterialUI.Grid>
                    <MaterialUI.Grid item>
                        <MaterialUI.Button aria-label="more apps" className={classes.menuIcon}>
                            <MaterialUIIcon.MoreHoriz/>
                        </MaterialUI.Button>
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
                <MaterialUI.Grid container justify="center" wrap="wrap" className={classes.sectionMobile}>
                </MaterialUI.Grid>
                <MaterialUI.Box display="flex" justifyContent="flex-end">
                    <MaterialUI.Box m={1}>
                        <MaterialUI.Select
                            labelId="language"
                            id="lang-dropdown"
                            value={locale}
                            onChange={handleChangeLanguage}
                        >
                            <MaterialUI.MenuItem value={"en"}>English</MaterialUI.MenuItem>
                            <MaterialUI.MenuItem value={"ko"}>한국어</MaterialUI.MenuItem>
                        </MaterialUI.Select>
                    </MaterialUI.Box>
                    <MaterialUI.Box m={1}>
                        {sign}
                    </MaterialUI.Box>
                </MaterialUI.Box>
        </MaterialUI.Toolbar>
        <MaterialUI.Toolbar className={classes.sectionMobile}>
            <MaterialUI.Grid container justify="center" wrap="wrap" >
                <MaterialUI.Grid item>
                    <MaterialUI.Button aria-label="activity" className={classes.menuIcon} onClick={goActivity}>
                        <MaterialUIIcon.Create/>
                    </MaterialUI.Button>
                </MaterialUI.Grid>
                <MaterialUI.Grid item>
                    <MaterialUI.Button aria-label="store" className={classes.menuIcon} onClick={goStore}>
                        <MaterialUIIcon.CardGiftcard/>
                    </MaterialUI.Button>
                </MaterialUI.Grid>
                <MaterialUI.Grid item>
                    <MaterialUI.Button aria-label="more apps" className={classes.menuIcon}>
                        <MaterialUIIcon.MoreHoriz/>
                    </MaterialUI.Button>
                </MaterialUI.Grid>
            </MaterialUI.Grid>
        </MaterialUI.Toolbar>
    </MaterialUI.AppBar>
    )
})