import React, {useEffect, useRef} from "react";
import ReactDOM from 'react-dom';
import {observer, useObserver} from "mobx-react";
import * as MaterialUI from '@material-ui/core/';
import * as MaterialUIIcon from '@material-ui/icons'
import {makeStyles} from '@material-ui/core/styles';

import jwt from 'jwt-decode'
import Cookies from "js-cookie";
import Clock from "react-digital-clock";
import ScrollContainer from 'react-indiana-drag-scroll'
import {useStores} from "../hooks/useStores";

import '../css/Drawer.css';
import '../css/Timeline.css';
import {BroadcastSchedule} from "../agent";

const useStyles = makeStyles((theme) => ({
    clock: {
        position: 'absolute',
        top: 5,
        right: 10,
        fontSize: 12,
        [theme.breakpoints.down('sm')]: {
            top: 5,
            right: 10,
            fontSize: 12,
        }
    },
    storeButton: {
        position: 'absolute',
        bottom: 40,
        right: 10,
        color: 'white',
        borderRadius: 100,
        textAlign: 'center',
        verticalAlign: 'middle',
        lineHeight: 3,
        width: 140,
        height: 30,
        cursor: 'pointer',
        boxShadow: "0px 5px rgba(184, 33, 174)",
        backgroundImage: 'linear-gradient(-25deg, #eb26de, #eb2695)'
    },
    bottomDrawerMessage: {
        textAlign:'center',
        height:200,
        lineHeight: 15
    }
}));

export const BroadcastScheduleButton = () => {
    const now = new Date();
    const zeroPad = (num) => {
        return num.toString().padStart(2,'0');
    }
    const { commonStore, videoJsPlayerStore } = useStores();
    const handleOnClick = () => {
        videoJsPlayerStore.setBottomDrawerOpen(!videoJsPlayerStore.bottomDrawerOpen);
    }

    return (
        <MaterialUIIcon.PlaylistPlay onClick={handleOnClick}/>
    )
}

export const AccountButton = () => {
    const { videoJsPlayerStore } = useStores();

    const handleOnClick = () => {
        videoJsPlayerStore.setRightDrawerOpen(!videoJsPlayerStore.rightDrawerOpen)
    }

    return (
        <MaterialUIIcon.AccountBox onClick={handleOnClick}/>
    )
}

export const StoreButton = () => {
    const classes = useStyles();
    const handleOnClick = () => {
        window.location.href="https://store.pinkfong.com";
    }

    return (
        <div className={classes.storeButton} onClick={handleOnClick}>
            Go to the Pinkfong store >
        </div>
    )
}

const TopDrawer = (props) => {
    const { videoJsPlayerStore } = useStores();

    const DrawerContents = () => (
        <div className={"top-drawer-contents-container"}>
            <div className={"drawer-close"} onClick={()=> videoJsPlayerStore.setTopDrawerOpen(false)}/>
        </div>
    );

    const Drawer = () => (
        <div className={`top-drawer-container ${props.isOpen && "top-drawer-container-is-open"}`}>
            <DrawerContents />
        </div>
    );
    return (
        <div className={"top-app-container"}>
            <Drawer/>
        </div>
    )
}

const RightDrawer = (props) => {
    const { commonStore, videoJsPlayerStore } = useStores();

    const signInAndOut = commonStore.logged ?
        <SignOutButton logged={commonStore.logged}/>:
        <SignInButton logged={commonStore.logged}/>

    const DrawerContents = () => (
        <div className={"right-drawer-contents-container"}>
            <div className={"drawer-close"} onClick={()=> videoJsPlayerStore.setRightDrawerOpen(false)}/>
            {signInAndOut}
        </div>
    );

    const Drawer = () => (
        <div className={`right-drawer-container ${props.isOpen && "right-drawer-container-is-open"}`}>
            <DrawerContents />
        </div>
    );
    return (
        <div className={"right-app-container"}>
            <Drawer/>
        </div>
    )
}

const SignInButton = (props) => {
    const { commonStore } = useStores();
    const [logged, setLogged] = React.useState(commonStore.logged);
    const handleSignIn = () => {
        Cookies.set('redirect_uri', window.location.href, {path:'/', domain: process.env.REACT_APP_ROOT_DOMAIN});
        window.location.href=`${process.env.REACT_APP_API_URL}/oauth2/authorization/pinkfong`;
    }

    if (!logged) {
        return (
            <MaterialUI.Box m={1} p={1}>
                <MaterialUI.Box display="flex"
                                alignItems="center"
                                justifyContent="center">
                    <MaterialUI.Button color={"secondary"} onClick={handleSignIn}>
                        SignIn
                    </MaterialUI.Button>
                </MaterialUI.Box>
            </MaterialUI.Box>
        )
    }
    return null;
}

const SignOutButton = (props) => {
    const [logged, setLogged] = React.useState(props.logged);
    const { commonStore, videoJsPlayerStore } = useStores();
    const handleSignOut = () => {
        window.localStorage.removeItem('jwt');
        commonStore.setLogged(false);
        setLogged(false);
        videoJsPlayerStore.setRightDrawerOpen(false);
    };

    if (logged) {
        return (
            <MaterialUI.Box m={1} p={1}>
                <MaterialUI.Box display="flex"
                                alignItems="center"
                                justifyContent="center">
                    <MaterialUI.Avatar alt={commonStore.user.email} src="/broken-image.jpg" />
                </MaterialUI.Box>
                <MaterialUI.Box>
                    <MaterialUI.Typography align={"center"}>
                        Hi! {commonStore.user.email.split('@')[0]}
                    </MaterialUI.Typography>
                </MaterialUI.Box>
                <MaterialUI.Box display="flex"
                                alignItems="center"
                                justifyContent="center">
                    <MaterialUI.Button onClick={handleSignOut}>
                        SignOut
                    </MaterialUI.Button>
                </MaterialUI.Box>
            </MaterialUI.Box>
        )
    }
    return null;
}

const BottomDrawer = (props) => {
    const classes = useStyles();
    const { commonStore, videoJsPlayerStore } = useStores();

    const DrawerContents = () => {
        if (!commonStore.logged) return <div className={classes.bottomDrawerMessage}>Please log in first.</div>
        return (
            <div className={"bottom-drawer-contents-container"}>
                <div className={"drawer-close"} onClick={() => {videoJsPlayerStore.setBottomDrawerOpen(false);}}/>
                <Timeline/>
            </div>)
    };

    const Drawer = () => (
        <div className={`bottom-drawer-container ${props.isOpen && "bottom-drawer-container-is-open"}`}>
            <DrawerContents/>
        </div>
    );


    return (
        <div className={"bottom-app-container"}>
            <Drawer/>
        </div>
    )
}

const DisplayClick = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.clock}>
            <MaterialUI.Box display={"flex"} justifyContent={"center"}>
            <MaterialUI.Box><Clock locale={"en-US"} hour12={true} format={"hh-mm-ss"}/></MaterialUI.Box>
            <MaterialUI.Box> KST</MaterialUI.Box>
            </MaterialUI.Box>
        </div>
    )
}

const Timeline = () => {
    const now = new Date;
    const classes = useStyles();
    const zeroPad = (num) => {
        return num.toString().padStart(2,'0');
    }
    const {commonStore, videoJsPlayerStore} = useStores();
    const [displayTimeline, setDisplayTimeline] = React.useState(false);
    const [items, setItems] = React.useState([]);
    const reggie = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/;
    const container = React.useRef(null);

    const dateStringToDateObject = (dateString) => {
        let dateArray = reggie.exec(dateString);
        let dateObject = new Date(
            (+dateArray[1]),
            (zeroPad(+dateArray[2]-1)), // Careful, month starts at 0!
            (zeroPad(+dateArray[3])),
            (zeroPad(+dateArray[4])),
            (zeroPad(+dateArray[5])),
            (zeroPad(+dateArray[6])),
        );
        return dateObject;
    }

    const isActive = (dateString) => {
        return dateStringToDateObject(dateString) <= now;
    }

    const getPercent = (dateString) => {
        let secondsInADay = 24 * 60 * 60;
        let now = dateStringToDateObject(dateString);
        let hours = now.getHours() * 60 * 60;
        let minutes = now.getMinutes() * 60;
        let seconds = now.getSeconds();
        let totalSeconds = hours + minutes + seconds;
        return Math.round(100 * totalSeconds/secondsInADay);
    }

    useEffect(() => {
        if (videoJsPlayerStore.bottomDrawerOpen) {
            BroadcastSchedule.get(`${now.getFullYear()}-${zeroPad(now.getMonth() + 1)}-${zeroPad(now.getDate())}`)
                .then(list => {
                    videoJsPlayerStore.setBroadcastScheduleList(list);
                    setItems(list);
                });
        }
        videoJsPlayerStore.setCurrentTimeline(getPercent(`${zeroPad(now.getFullYear())}-${zeroPad(now.getMonth()+1)}-${zeroPad(now.getDate())} ${zeroPad(now.getHours())}:${zeroPad(now.getMinutes())}:00`));
        setDisplayTimeline(true);
        if (videoJsPlayerStore.bottomDrawerOpen) {
            container.current.getElement().scrollTo((videoJsPlayerStore.currentTimeline * 100 - 200), 0);
        }
    },[]);

    return (
        <ScrollContainer className={"container"} style={{height: 200}} ref={container}>
            <div className={"timeline"} style={{width:10000, opacity:displayTimeline?1:0}}>
                <div className={"timeline-progress"} style={{width: 10000 * videoJsPlayerStore.currentTimeline / 100}} />
                <div className={"timeline-items"}>
                    {items.map((item, i) => (
                        <div key={i} className={"timeline-item" + (isActive(item.startDate+' '+item.startTime) ? ' active' : '')} style={{marginLeft:-9.8, left:getPercent(`${item.startDate} ${item.startTime}`)+'%'}}>
                            <div className={"timeline-content-" +(i%2===0?"top":"bottom")}>
                                <MaterialUI.Grid container>
                                    <MaterialUI.Grid pb={1} item>{item.startTime}</MaterialUI.Grid>
                                    <MaterialUI.Grid item>{item.title}</MaterialUI.Grid>
                                    <MaterialUI.Grid pt={1} item>{item.subTitle}</MaterialUI.Grid>
                                </MaterialUI.Grid>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </ScrollContainer>
    )
}

export const VideoJsOverlay = observer(() => {
    const { videoJsPlayerStore } = useStores();
    return useObserver (() => (
        <MaterialUI.Box>
            <DisplayClick/>
            {/*<StoreButton/>*/}
            <RightDrawer isOpen={videoJsPlayerStore.rightDrawerOpen}/>
            <BottomDrawer isOpen={videoJsPlayerStore.bottomDrawerOpen}/>
            <TopDrawer isOpen={videoJsPlayerStore.topDrawerOpen}/>
        </MaterialUI.Box>
    ))
})



