import React from 'react';
import videojs from 'video.js';
import 'videojs-overlay';
import 'videojs-http-source-selector'
import 'videojs-contrib-quality-levels'
import * as ReactDOM from "react-dom";
import { VideoJsOverlay, BroadcastScheduleButton, AccountButton } from './VideoJsOverlay'
import * as MaterialUIIcon from '@material-ui/icons/';

import '../css/VideoJsPlayer.css';
import '../css/VideoJsOverlay.css';
import '../css/Drawer.css';

const styles = {
    wrapper:{
        boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.5)',
        margin: '0 auto',
        /*minWidth: 320*/
    }
};
export class VideoJsPlayer extends React.Component {
    componentDidMount() {
        window.videojs = videojs;
        this.player = window.videojs(this.videoNode, this.props, function onPlayerReady() {
            console.log('onPlayerReady', this)
        });

        this.player.overlay({
            overlays: [{
                content: '<div id="video-overlay-elements"/>',
                start: 'playing'
            }]
        });

        if (this.props.showCustomButton) {
            const bsBtn = this.player.controlBar.addChild('button');
            const bsBtnDom = bsBtn.el();
            bsBtnDom.innerHTML = '<div id="video-custom-control-broadcast-schedule"/>'

            bsBtnDom.onclick = function(event){
                event.preventDefault();
            }

            const accountBtn = this.player.controlBar.addChild('button');
            const accountBtnDom = accountBtn.el();
            accountBtnDom.innerHTML = '<div id="video-custom-control-account"/>'

            accountBtnDom.onclick = function(event){
                event.preventDefault();
            }

           /* const storeBtn = this.player.controlBar.addChild('button');
            const storeBtnDom = storeBtn.el();
            storeBtnDom.innerHTML = '<div id="video-custom-control-store"/>'

            storeBtnDom.onclick = function(event){
                window.location.href='https://store.pinkfong.com/'
            }*/

            const videoJsOverlay = () => {
                ReactDOM.render(
                    <VideoJsOverlay/>
                    , document.getElementById('video-overlay-elements'));
            }

            const videoJsBroadcastScheduleControl = () => {
                ReactDOM.render(
                    <BroadcastScheduleButton/>
                    , document.getElementById('video-custom-control-broadcast-schedule'));
            }

            const videoJsAccountControl = () => {
                ReactDOM.render(
                    <AccountButton/>
                    , document.getElementById('video-custom-control-account'));
            }

           /* const videoJsStoreControl = () => {
                ReactDOM.render(
                    <MaterialUIIcon.Storefront/>
                    , document.getElementById('video-custom-control-store'));
            }*/

            videoJsOverlay();
            videoJsBroadcastScheduleControl();
            //videoJsStoreControl();
            //videoJsAccountControl();
        }
        if (this.props.forcePlay) {
            this.player.play();
        }
        this.player.httpSourceSelector();
    }

    componentWillUnmount() {
        if (this.player) {
            this.player.dispose()
        }
    }

    render() {
        return (
            <div id={"vjs-wrapper"} style={styles.wrapper}>
                <div data-vjs-player>
                    <video id={"video-js"} playsInline crossOrigin={"anonymous"} ref={ node => this.videoNode = node } className={"video-js"}
                        poster="https://bread-medialive-output.s3.ap-northeast-2.amazonaws.com/img/screen-test.png"
                    ></video>
                </div>
            </div>
        )
    }
}