import React from "react";
import ReactDOM from 'react-dom'
import { configure } from 'mobx'
import { Provider } from 'mobx-react'
import promiseFinally from 'promise.prototype.finally'

import { App } from './App'

promiseFinally.shim();
configure({enforceActions: 'observed'})

ReactDOM.render(
    <Provider>
        <App />
    </Provider>,
    document.getElementById('root')
);


