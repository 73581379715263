import React, {Component} from "react";
import {observer, useObserver} from "mobx-react"
import {useStores} from "./hooks/useStores";
import Cookies from "js-cookie";
import { BrowserRouter, HashRouter, Switch, Route } from "react-router-dom";

import { LiveVideoPlayer } from "./pages/LiveVideoPlayer";
import { BroadcastVideoPlayer } from "./pages/BroadcastVideoPlayer";
import { BabyShark } from "./pages/BabyShark";
import { Gnb } from "./components/Gnb";
import { BabySharkActivity } from "./pages/BabySharkActivity";
import { BabySharkStore } from "./pages/BabySharkStore";
import {BabySharkActivityDetail} from "./pages/BabySharkActivityDetail";

export const App  = observer(() => {
    const { commonStore, videoJsPlayerStore } = useStores();

    const token = Cookies.get('jwt', { path: '/', domain: process.env.REACT_APP_ROOT_DOMAIN })
    if (token) { //쿠키에 jwt 값이 있다면 commonStore 로 옮긴다.
        commonStore.setToken(token);
        Cookies.remove('jwt', {path: '/', domain: process.env.REACT_APP_ROOT_DOMAIN});
    }

    commonStore.verifyToken();

    return useObserver (() => (
        <>
            <Gnb/>
            <HashRouter>
                <Switch>
                    <Route path={"/live"} component={LiveVideoPlayer}/>
                    <Route path={"/broadcast"} component={BroadcastVideoPlayer}/>
                    <Route exact={true} path={"/"} component={BabyShark}/>
                    <Route path={"/activity"} component={BabySharkActivity}/>
                    <Route path={"/detail"} component={BabySharkActivityDetail}/>
                    <Route path={"/store"} component={BabySharkStore}/>
                </Switch>
            </HashRouter>
        </>
    ))
})