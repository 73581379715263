import React, {useEffect} from 'react';
import * as MaterialUI from "@material-ui/core";
import {makeStyles, useTheme} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useStores} from "../hooks/useStores";
import {observer, useObserver} from "mobx-react";
import * as MaterialUIIcon from "@material-ui/icons";
import FileSaver from 'file-saver';

const useStyles = makeStyles(theme => ({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
    gridListRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflowX: 'hidden',
        overflowY: 'hidden',
        overflowScrolling: "touch",
        WebkitOverflowScrolling: "touch",
        backgroundColor: theme.palette.background.paper,
    },
    gridList: {
        [theme.breakpoints.up('xl')]: { //1920
            width: 1344,
            height: 510,
        },
        [theme.breakpoints.down('lg')]: { //960
            width: 1200,
            height: 510,
        },
        [theme.breakpoints.down('md')]: { //960
            width: 740,
            height: 270,
        },
        [theme.breakpoints.down('sm')]: { //960
            width: 550,
            height: '100%',
        },
        [theme.breakpoints.down('xs')]: { //600
            width: 360,
            height: '100%',
        },
    },
    selectedIcon: {
        width: 80,
    },
    selectedBg: {
        zIndex:99,
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%'
    },
    tileBgUnlock: {
        zIndex:98,
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    tileBgLock: {
        zIndex:98,
        position: 'absolute',
        top: 0,
        left: 0,
        backgroundColor: 'rgba(255,255,255,0.5)',
        width: '100%',
        height: '100%'
    },
    itemImage: {
        [theme.breakpoints.up('sm')]: { //960
            height: 480,
        },
        [theme.breakpoints.down('sm')]: { //960
            width: 300,
        },
        [theme.breakpoints.down('xs')]: { //600
            width: 330,
        },
    },
    itemContentsBar: {
        zIndex:99,
        padding: 10,
        bottom: 4,
        left: 0,
        right: 0,
        height: 48,
        display: 'flex',
        position: 'absolute',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        alignItems: 'center',
        fontFamily: "Roboto,Helvetica,Arial,sans-serif",
    },
    itemContentTitle:{
        color: '#fff',
        overflow: 'hidden',
        flexGrow: 1,
        marginLeft: '16',
        marginRight: '16'
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
    },
    sectionMobile: {
        display: 'block',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export const BabySharkActivityDetail = observer(() => {
    const classes = useStyles();
    const theme = useTheme();
    const {commonStore} = useStores();
    const [selectedItem, setSelectedItem] = React.useState(0);
    const screenExtraLarge = useMediaQuery(theme.breakpoints.only('xl'));
    const screenLarge = useMediaQuery(theme.breakpoints.only('lg'));
    const screenMedium = useMediaQuery(theme.breakpoints.only('md'));
    const screenSmall = useMediaQuery(theme.breakpoints.only('sm'));
    const screenExtraSmall = useMediaQuery(theme.breakpoints.only('xs'));
    const screenNarrow = useMediaQuery('(max-width:340px)');

    const getCellHeight = () => {
        if (screenExtraLarge) {
            return 250;
        } else if (screenNarrow) {
            return 250;
        } else if (screenLarge) {
            return 220;
        } else if (screenMedium) {
            return 130;
        } else if (screenSmall) {
            return 500;
        } else if (screenExtraSmall) {
            return 500;
        } else {
            return 250;
        }
    }

    const gridListData = [
        {"link":"/1/all.zip","lock":false,"id":1,"titleEn":"Decorating the house","subTitleEn":"Decorating the house","title":"집꾸미기","subTitle":"일단 집을 사자","list":["/1/1.png", "/1/2.png", "/1/3.png", "/1/4.png"]},
        {"link":"/2/all.zip","lock":false,"id":2,"titleEn":"Coloring","subTitleEn":"Coloring","title":"색칠놀이","subTitle":"색칠을 해보자아","list":["/2/1.png", "/2/2.png", "/2/3.png", "/2/4.png"]},
        {"link":"/3/all.zip","lock":false,"id":3,"titleEn":"Maze","subTitleEn":"Maze","title":"미로찾기","subTitle":"사랑의 미로여","list":["/3/1.png", "/3/2.png", "/3/3.png", "/3/4.png"]},
        {"link":"/4/all.zip","lock":false,"id":4,"titleEn":"Draw by number","subTitleEn":"Draw by number","title":"숫자따라그리기","subTitle":"우선 숫자를 먼저 배우자","list":["/4/1.png", "/4/2.png", "/4/3.png", "/4/4.png"]},
        {"link":"/5/all.zip","lock":true,"id":5,"titleEn":"Dress up","subTitleEn":"Dress up","title":"옷입히기","subTitle":"1년내내 같은옷","list":["/5/1.png", "/5/2.png", "/5/3.png", "/5/4.png"]},
        {"link":"/6/all.zip","lock":true,"id":6,"titleEn":"Schedule collection","subTitleEn":"Schedule collection","title":"계획표 모음","subTitle":"너는 다 계획이 있구나","list":["/6/1.png", "/6/2.png", "/6/3.png"]},
    ]

    useEffect(()=>{
        setSelectedItem(window.localStorage.getItem('activityDetailSelectedNumber'))
        commonStore.setDetailMode(true);
    },[])

    const handleBack = () => {
        window.history.back();
    }

    return useObserver( () =>
        <>
            <MaterialUI.AppBar position="static" style={{backgroundColor: 'white'}}>
                <MaterialUI.Toolbar>
                    <MaterialUI.Box display="flex" justify="flex-start">
                       <MaterialUIIcon.ArrowBack color={"primary"} fontSize={"large"} onClick={handleBack}/>
                       <MaterialUI.Typography style={{margin:7, color:"black"}}>{commonStore.locale === 'en'? gridListData[selectedItem].titleEn:gridListData[selectedItem].title}</MaterialUI.Typography>
                    </MaterialUI.Box>
                </MaterialUI.Toolbar>
            </MaterialUI.AppBar>
            <MaterialUI.Box m={3}>
               <div className={classes.gridListRoot}>
                  <MaterialUI.GridList cols={1} cellHeight={getCellHeight()} className={classes.gridList}>
                   {gridListData[selectedItem].list.map(url => (
                                <MaterialUI.GridListTile key={url}>
                                    <MaterialUI.GridListTileBar  className={classes.itemContentsBar}
                                                                actionIcon={
                                                                    <MaterialUI.IconButton onClick={()=> FileSaver.saveAs(process.env.REACT_APP_CDN_DOMAIN+"/activity"+url, 'pinkfong.png')}>
                                                                        <MaterialUIIcon.CloudDownload fontSize={"small"} style={{color:'white', border:'1px solid', padding: 10, borderRadius:'50%'}}/>
                                                                    </MaterialUI.IconButton>
                                                                }
                                    />
                                    <img src={process.env.REACT_APP_CDN_DOMAIN+"/activity"+url} />
                                </MaterialUI.GridListTile>
                        ))}
                    </MaterialUI.GridList>
                </div>
            </MaterialUI.Box>
        </>
    );
})
