import {action, observable, reaction} from 'mobx'
import {Auth} from '../agent'

class CommonStore {
    @observable
    appName = 'BPlayer';

    @observable
    token = window.localStorage.getItem('jwt');

    @observable
    appLoaded = false;

    @observable
    user;

    @observable
    logged = false;

    @observable
    selectedMenuIndex = 0;

    @observable
    locale = 'en';

    @observable
    detailMode = false;

    @observable
    deviceSize = '';

    constructor() {
        reaction(
            () => this.token,
            token => {
                if (token) {
                    window.localStorage.setItem('jwt', token);

                } else {
                    window.localStorage.removeItem('jwt');
                }
            }
        );
    }

    @action verifyToken() {
        // jwt 토큰으로 서버로 부터 인증 정보를 가져와서 넣는다.
        if(this.token) {
            Auth.current().then((user) => this.setUser(user)).finally(() => this.setLogged(true));
        } else {
            this.setLogged(false);
        }
    }

    @action
    setToken(token) {
        this.token = token;
    }

    @action setAppLoaded(appLoaded) {
        this.appLoaded = appLoaded;
    }

    @action
    setUser(user) {
        this.user = user;
    }

    @action setLogged(logged) {
        this.logged = logged;
    }

    @action setSelectedMenuIndex(selectedMenuIndex) {
        this.selectedMenuIndex = selectedMenuIndex;
    }

    @action setLocale(locale) {
        this.locale = locale;
    }

    @action setDetailMode(detailMode) {
        this.detailMode = detailMode;
    }

    @action setDeviceSize(deviceSize) {
        this.deviceSize = deviceSize;
    }
}
export default new CommonStore();