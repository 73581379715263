import React from 'react';
import {VideoJsPlayer} from '../components/VideoJsPlayer';
import * as MaterialUI from "@material-ui/core";
import * as MaterialUIIcon from "@material-ui/icons";

const styles = {
    container: {
        zIndex: 11,
        overflowX: 'hidden',
        overflowY: 'hidden',
        overflowScrolling: "touch",
        WebkitOverflowScrolling: "touch",
    }
};

export const LiveVideoPlayer = () => {
    const videoJsOptions = {
        autoplay: 'any',
        controls: true,
        preload: 'auto',
        width: 360,
        height: 640,
        muted: true,
        playsInline: true,
        //fluid: true,
        //fullscreen: true,
        //responsive: true,
        textTrackSettings: false,
        //aspectRatio: '16:9'
        controlBar: {
            volumePanel: {
                inline: false,
                volumeControl: {
                    vertical: true
                }
            }
        },
        sources: [{
            type: "application/x-mpegURL",
            src: process.env.REACT_APP_LIVE_STREAM_URL
        }],
    }

    return (
        <div style={styles.container}>
            <MaterialUI.Box>
                <MaterialUI.Grid container
                                 spacing={0}
                                 direction="column"
                                 alignItems="center"
                                 justify="center"
                                 style={{ minHeight: '100vh'}}>
                    <MaterialUI.Grid item>
                        <VideoJsPlayer showCustomButton={false} forcePlay={true} {...videoJsOptions}/>
                    </MaterialUI.Grid>
                </MaterialUI.Grid>
            </MaterialUI.Box>
        </div>
    );
}
